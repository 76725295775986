<template>
  <div class="card">
    <div class="card-header">
      <h3 class="cart-title">
        <a data-action="collapse">Personal address</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-content collapse show">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="mb-1 form-group required">
              <label
                class="form-label"
                for=""
              >{{ labels['address'] }}</label>
              <textarea
                v-model="user.address"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1 form-group required">
              <label
                class="form-label"
                for=""
              >{{ labels['city'] }} </label>
              <input
                v-model="user.city"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1 form-group required">
              <label
                class="form-label"
                for=""
              >{{ labels['postal_code'] }}</label>
              <input
                v-model="user.postal_code"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-1 form-group required">
              <label
                class="form-label"
                for="select2-basic"
              >{{ labels['country'] }}</label>
              <v-select
                v-model="user.personal_country"
                label="name"
                :options="countries"
                :get-option-key="option => option.id"
                :reduce="option => option.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  props: {
    user: { type: Object, required: true, default: () => {} },
  },
  data() {
    return {
      labels: [],
    }
  },
  computed: {
    ...mapGetters({
      countries: 'citizenships/items',
    }),
  },
  async mounted() {
    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.personal_address`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
    if (this.countries.length === 0) {
      this.$store.dispatch('citizenships/fetch')
    }
  },
}
</script>

<style>

</style>
